import React from 'react';

function Footer() {  
  return (
    <div className="footer">
        COPYRIGHT © 2023 JARKKO LAGUS - ALL RIGHTS RESERVED
    </div>
  );
}

export default Footer;